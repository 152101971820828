import React from 'react';
import { Button } from '@material-ui/core';
import Scroll from "react-scroll";
import { useHistory } from "react-router-dom";
import "./Banner.css";

const Banner = () => {
    const ScrollLink = Scroll.Link;
    const history = useHistory();

    return (
        <div>
            <div className='banner'>
                <img src='/images/career.jpg' alt='banner' width="100%" height="100%" />

                     <div className='heading-top1'>
                <h5 className='heading-top2'>Careers at <span style={{ color: "rgb(0, 150, 136)" }}>Mobiloitte</span></h5>
                <h1 className='heading-top3'>Work with us</h1>
                <p className='heading-bottom2'>Explore Fulfilling, Remote-Friendly, and <br />Flexible Career Opportunities at Mobiloitte</p>
                <ScrollLink smooth={true} duration={500} to="apply">
                    <Button className='contactus'>
                        View Careers
                    </Button>
                </ScrollLink>
            </div>

            </div>

           
        </div>
    );
}

export default Banner;
