import React,{useState} from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Scroll from "react-scroll";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import "./Pathway.css";
import { Button } from '@material-ui/core';
import BusinessDevelopmentExecutive from './BusinessDevelopmentExecutive';
import { Link } from 'react-router-dom';
const Pathway = () => {
    
    const ScrollLink = Scroll.Link;
    return (
        <div className='pathway-container'>
            <h1 className='pathway-title'>
                Career Pathways
            </h1>
            <p className='pathway-paragraph'>
                Discover Our Remote, In-Office, and Hybrid Career Opportunities"
            </p>
            <div className='card-1'>
                <h2 className='pathway-heading'>
                    Business Development Executive (India)
                </h2>
                <p className='pathway-headind-title'>
                    Embark on an Exciting Journey by Becoming a Valued Member of Our Team as a Business Development Executive in the United State, Where Your Expertise Will Drive Growth and Foster Strong Client Relationships.
                </p>
                <div className='pathway-icon'>
                    <div className='pathway-map'>
                        <LocationOnIcon fontSize='small' /> <span>India</span>
                    </div>
                    <div className='pathway-clock'>
                        <QueryBuilderIcon fontSize='small' /> <span>Full Time</span>

                    </div>

                </div>
                {/* <ScrollLink smooth={true} duration={500} to="bussiness"> */}
                <Link to="/business">
                <Button className='apply-btn-business'>
                    Apply Now
                </Button>
                </Link>
                {/* </ScrollLink> */}
            </div>
            <div className='card-1'>
                <h2 className='pathway-heading'>
                    Software Engineer (India)
                </h2>
                <p className='pathway-headind-title'>
                    Join Our Team of Innovators in the United State: Crafting Cutting-Edge Solutions, Contributing to Thrilling Projects, and Enriching Our Mission for Technological Excellence.
                </p>
                <div className='pathway-icon'>
                    <div className='pathway-map'>
                        <LocationOnIcon fontSize='small' /> <span>India</span>
                    </div>
                    <div className='pathway-clock'>
                        <QueryBuilderIcon fontSize='small' /> <span>Full Time</span>

                    </div>

                </div>
                <ScrollLink smooth={true} duration={500} to="">
                    <Link to="softwarejobdetail">
                    <Button className='apply-btn-business'>
                        Apply Now
                    </Button>
                    </Link>
                  
                </ScrollLink>
            </div>
            <div className='card-1'>
                <h2 className='pathway-heading'>
                    Senior Consultant (India)
                </h2>
                <p className='pathway-headind-title'>
                    Join Our Vibrant and Dynamic Team, Where Your Expertise Will Be Instrumental in Providing Profound Guidance and Innovative Solutions. Contribute Significantly to High-Impact Strategic Projects, and Play a Key Role in Igniting Business Success in the Thriving US Market.
                </p>
                <div className='pathway-icon'>
                    <div className='pathway-map'>
                        <LocationOnIcon fontSize='small' /> <span>India</span>
                    </div>
                    <div className='pathway-clock'>
                        <QueryBuilderIcon fontSize='small' /> <span>Full Time</span>

                    </div>

                </div>
                <ScrollLink smooth={true} duration={500} to="contact">
                    <Link to="SeniorConsultan">
                    <Button className='apply-btn-business'>
                        Apply Now
                    </Button>
                    </Link>
                
                </ScrollLink>
            </div>
        </div>
    )
}

export default Pathway