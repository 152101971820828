import { Box, Container, Typography, } from "@material-ui/core";
import {React,useEffect} from "react";
import Scroll from "react-scroll";
import { Button, makeStyles } from "@material-ui/core"
import { Link } from 'react-router-dom';
import Basic from "src/Basic/Basic";
const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: '40px',
      fontFamily: 'poppins',
      fontWeight: '400',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px', 

        marginTop: "65px",// Adjust font size for smaller screens
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        marginTop: '50px',
        marginLeft: '-2px'
        // Adjust font size for extra small screens
      },
    },
  }));
function SeniorConsultan() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
    const classes = useStyles();
    const ScrollLink = Scroll.Link;
    const styles = {
        header: {
            backgroundColor: "#00247E",
            color: "white",
            marginTop: "5%"
        },
    
        button: {
            color: "#fff",
            backgroundColor: "rgb(0, 150, 136)",
            border: "none",
            textTransform: "none",
            height: "3rem",
            width: "8rem",
            fontSize: "18px",
            marginTop: "40px",
            '&:hover': {
                backgroundColor: "#06038D", // Change the background color on hover
            },
        },
        paragraph: {
            display: "flex",
            flexDirection: "row",
            
        },
   
        
    };

    return (
        <Basic>

      
        <Box>
            <Box sx={styles.header}>
                <Box sx={{ marginLeft: "4%", paddingTop: "9%", paddingBottom: "8%" }}>
                    <Container>
                        <Typography variant="h3" style={{ fontSize: "40px", fontFamily: "poppins", fontWeight: "400" }} className={classes.header}>
                        Senior Consultant
                        </Typography>
                    </Container>

                    <Container>
                        <Typography variant="h3" style={{ fontSize: "30px", marginTop: "20px", fontFamily: "poppins", fontWeight: "400" }}>India</Typography>
                    </Container>

                
                    <Container>
                        <ScrollLink smooth={true} duration={500} to="pathway">
                            <Link to="JobApplication">
                            <Button className={classes.contactus} style={{ marginTop: "20px", ...styles.button }} >
                                Apply Now
                            </Button>
                            </Link>
                       
                        </ScrollLink>
                    </Container>
                </Box>
            </Box>

            {/* --------------------------------------------------- */}

            <Box sx={{ marginLeft: "4%", paddingTop: "3%", paddingBottom: "3%" }}>
                <Container>
                    <Typography variant="h5" style={{ fontWeight: "550", fontSize: "30px" }}>
                        <span>Duties and Responsibilities</span>
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>
                
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        
                    <span className="dot-before">&#8226;</span> Lead and Manage Consulting Projects for Clients in the India, Ensuring the Delivery of High-Quality Solutions and Services.
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Perform Comprehensive Assessments and In-Depth Analysis of Client Needs, Challenges, and Opportunities in the India.
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Develop and Implement Strategic Recommendations to Address Client Issues and Achieve Their Objectives within the India market.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Collaborate with Cross-Functional Teams to Design and Deliver Innovative Solutions that Align with India Market Dynamics.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>Provide Expert Guidance and Mentorship to Junior Consultants Within the Organization in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Establish and Maintain Strong Relationships with India Clients, Earning TrIndiat as a TrIndiated Advisor.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                      <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Stay Current with IndIndiatry Trends, Regulations, and Best Practices Relevant to the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Assist in BIndiainess Development Efforts by Identifying Upsell and Cross-Sell Opportunities in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Monitor Project Budgets, Timelines, and Resource Allocation to Ensure Successful Project Execution within the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container style={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Contribute to Thought Leadership through Whitepapers, Presentations, and IndIndiatry-Specific Publications, specifically for the India career page.
                    </Typography>
                </Container>
                {/* Remaining responsibilities */}
            </Box>
            <Container >
                    <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Salary :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    According to the Market
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Job Type :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    Office/Hybrid
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Location :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    India
                    </Typography>
                </Container>
            {/* Remaining content */}
        </Box>
        </Basic>
    );
}

export default SeniorConsultan;
