import React from 'react'
import "./Pioneering.css";
const Pioneering = () => {
  return (
    <div className='career'>
        <h1 className='title'>
       <span>Mobiloitte </span> Pioneering the Work Revolution of Tomorrow
    </h1>
    <p className='paragraph'>Join Our Mission to Transform Work: Shaping a More Connected, Inclusive, and Flexible Future</p>
<div className='socialmedia'>
<div className='card-linkedin'>
<img src='/images/connect.svg' alt='connect' />
<h2 className='link'>LinkedIn</h2>
<p className='link-title'>Fostering Connections Beyond Boundaries,<br></br> Borders, and Devices</p>
</div>
<div className='card-diversity'>
<img src='/images/inclusive.svg' alt='connect' />
<h2 className='link'>Diverse and All-Encompossing</h2>
<p className='link-title'>Empowering Varied Voices and Advancing <br></br>Equality Within Our Workforce</p>
</div>
<div className='card-adaptable'>
<img src='/images/flexible.svg' alt='connect' />
<h2 className='link'>Adaptable</h2>
<p className='link-title'>Embracing Individual Autonomy for<br></br> Shared Success"</p>
</div>
</div>
    </div>
  )
}

export default Pioneering