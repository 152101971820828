import React from 'react';
import './Diversity.css';

const Diversity = () => {
    return (
        <div className='diversity-container'>
            <h1 className='diversity-title'>
                Diversity, Involvement, Inclusivity: Our Pledge
            </h1>
            <p className='diversity-paragraph'>
                At Mobiloitte, we prioritize the cultivation of inclusivity and a sense of belonging in our company. Our unwavering commitment lies in establishing an atmosphere where each person is valued, respected, and encouraged to share their distinct viewpoints. This ensures that diversity isn't merely acknowledged; it's cherished and celebrated as an integral part of our workplace culture.
            </p>
            <div className='diversity-section'>
                <div className='diversity-card'>
                    <h2 className='diversity-link'>Diversity, Participation, and a Sense of Belonging</h2>
                    <p className='diversity-link-title'>
                        At Mobiloitte, we wholeheartedly embrace diversity, prioritize engagement, and foster a strong sense of belonging. These principles are central to our dedication to cultivating an inclusive and vibrant workplace culture in the US.
                    </p>
                </div>
                <div className='diversity-card'>
                    <h2 className='diversity-link'>Rising Career Prospects</h2>
                    <p className='diversity-link-title'>
                        At Mobiloitte, we stand at the forefront of technology, continuously adapting and innovating to seize emerging opportunities, and providing cutting-edge solutions that redefine industries in the US.
                    </p>
                </div>
            </div>
            <div className='diversity-section'>
                <div className='diversity-card'>
                    <h2 className='diversity-link'>Affinity Networks and Inclusion Initiatives</h2>
                    <p className='diversity-link-title'>
                        At Mobiloitte, our Affinity Networks and Inclusion Initiatives lie at the core of our dedication to nurturing a diverse and inclusive workplace in the US, where every voice is not only heard but also deeply valued and empowered to thrive
                    </p>
                </div>
                <div className='diversity-card'>
                    <h2 className='diversity-link'>Strategic Collaborations</h2>
                    <p className='diversity-link-title'>
                        At Mobiloitte, our Strategic Collaborations serve as the cornerstone of our commitment to innovation and excellence within the US, as we partner with industry leaders to provide transformative solutions and promote collective success.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Diversity;
