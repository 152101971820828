import React from 'react'
import { useState } from 'react'

const Footer = () => {
    const [data] =useState([{
        id: 1,
        logo : "/images/uk.svg",
        title: "New Delhi",
        address: "D-115, Okhla Phase -I, New Delhi – 110020",
},
{
    id: 2,
    logo : "/images/ind.svg",
    title: "Pune",
    address: "Vasukamal, Mahalunge Rd, Lalit Estate, Baner, Pune, MH. – 411045",
},
{
    id: 3,
    logo : "/images/us.svg",
    title: "USA",
    address: "1302 Lords Ct Wilmington, MA 01887, USA",
},
{
    id: 4,
    logo : "/images/sg.svg",
    title: "Singapore",
    address: "10 10 High Street Centre1 North Bridge Rd, Singapore 179094",
},
{
    id: 5,
    logo : "/images/sa.png",
    title: "South Africa",
    address: "8 Ashwood Drive, Centurion 0185, South Africa",
},
{
    id: 6,
    logo : "/images/Ireland-Flag.svg",
    title: "Ireland",
    address: "Cavan Enterprise Centre, Dublin Road, Cavan, Co. Cavan , Ireland, H12 EW84",
},])
  return (
    <div>
        <section>

   
    <div className='footer'>
     {data.map((item, id)=>{
        <div key={id} className='content'>
            <img src={item.logo} />
            <h4>{item.title}</h4>
            <p>{item.address}</p>
        </div>
     })}
    </div>
    </section>
    </div>
  )
}

export default Footer
