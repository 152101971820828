import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const Logo = (props) => {
  const history = useHistory();

  const handleLogoClick = () => {
    // Use localStorage to store a flag indicating that the logo was clicked
    localStorage.setItem('logoClicked', 'true');
    window.location.reload();
  };

  // Check if the logo was clicked and redirect to BannerImg if needed
  React.useEffect(() => {
    const logoClicked = localStorage.getItem('logoClicked');
    if (logoClicked === 'true') {
      localStorage.removeItem('logoClicked');
      history.push('/');
    }
  }, [history]);

  return (
    <Link to="/header" onClick={handleLogoClick}>
      <img src="/images/1.png" alt="Logo" {...props} />
    </Link>
  );
};

export default Logo;
