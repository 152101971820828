import { React, useEffect } from 'react';
import Banner from './Banner'
import Pioneering from './Pioneering'
import Guiding from './Guiding'
import Welness from './Welness'
import Diversity from './Diversity'
import Pathway from './Pathway'
import { Box } from '@material-ui/core'
import Contact from '../Home/Contact'

import BusinessDevelopmentExecutive from './BusinessDevelopmentExecutive'
import Footer from '../Home/Footer'
import Header from 'src/layouts/HomeLayout/TopBar';

const Career = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box id="career">
        <Box className='header'>
          {/* <Header /> */}
        <Header />

        </Box>
        <Box className='pathway12' style={{ marginTop: "40px" }}>
          <Banner />
        </Box>

        <Pioneering />
        <Guiding />
        <Welness />
        <Diversity />
        <Box className='apply'>
          <Pathway />
        </Box>
        <Box className='contact'>
          <Contact />
        </Box>
        <Box className='footer'>
          <Footer />
        </Box>

      </Box>

    </div>
  )
}

export default Career