import React, { useState } from 'react';
import { StyleRoot } from 'radium';
import { useTheme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    display: "flex",
    '@media (max-width: 900px)': {
      // display: "none",
      flexDirection: "column !important"
    },
    
  },
    bannerTitle: {
    marginBottom: '15px',
    textAlign: 'center',
    fontSize: '16px',
    // Media Query for larger screens
    '@media (max-width: 599px)': {
      marginBottom: '0px',
   
      paddingRight: "33px !important",

    },

  },
  
}))
const styles = {
  cookiesBanner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    padding: '0px 20px',
    lineHeight: "1px",
    textAlign: 'center',
    zIndex: 1000,
  },



  acceptCookiesBtn: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '150px',
    marginTop: '0px',

    // Media Query for larger screens
    '@media (min-width: 768px)': {
      marginTop: '200px',
    },
  },
};

const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const classes = useStyles();

  const acceptCookies = () => {
    setShowBanner(false);
    // Set cookies or perform other actions upon accepting cookies
  };



  return (
    showBanner && (
      <div id="cookies-banner" style={styles.cookiesBanner}>
        <div className={classes.contentWrapper}>
          <div>
            <p className={classes.bannerTitle}>
              We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.
            </p>
          </div>&nbsp;
          <div>
            <button onClick={acceptCookies} style={styles.acceptCookiesBtn}>
              Got It
            </button>
          </div>
        </div>
      </div>
    )
  );
};



export default CookiesBanner;
