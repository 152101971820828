import { Box, Container, Typography, } from "@material-ui/core";
import React from "react";
import Scroll from "react-scroll";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '40px',
    fontFamily: 'poppins',
    fontWeight: '400',

    textAlign: "center",
    marginLeft: "30%",

    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      // Adjust font size for smaller screens
      fontSize: "29px",
      marginTop: "65px",
      width: "97%",
      marginRight: "30%",
      marginLeft: "10%",
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',

      width: "100%",
      marginLeft: "2rem"


    },
    '@media (min-width: 960px) and (max-width: 1024px)': {
      paddingLeft: "0%",
      textAlign: "justify",
    }
  },
  headerTitle: {
    fontSize: "30px",
    marginTop: "20px",
    fontFamily: "poppins",
    fontWeight: "400",
    textAlign: "center",
    marginLeft: "-11%",

    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
    '@media (min-width: 960px) and (max-width: 1024px)': {
      display: "none"
    }
  },
  maincontainer: {
    backgroundColor: "red"
  },
}));

function ApplyBanner() {
  const ScrollLink = Scroll.Link;
  const classes = useStyles();
  const styles = {
    header: {
      backgroundColor: "#00247E",
      color: "white",
      marginTop: "5%"
    },

    paragraph: {
      display: "flex",
      flexDirection: "row",
    },
  };

  // Adjust marginTop based on window width



  return (

    <Box sx={styles.header}>
      <Box sx={{ paddingTop: "9%", paddingBottom: "8%", width: "80%" }}>

        <Typography variant="h3" className={classes.header}>
          Your Career Journey Starts Here
        </Typography>


        <Container>
          <Typography variant="h3" className={classes.headerTitle} style={{}}>India</Typography>
        </Container>
      </Box>
    </Box>

  );
}

export default ApplyBanner;
