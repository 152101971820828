import { Typography, Card, CardContent, CardMedia, Grid } from '@material-ui/core';
import React, { useState } from 'react';

const Footer = () => {
  const [data] = useState([
  
    {
      id: "1",
      country: "/images/ind.svg",
      title: "Pune",
      address: "Vasukamal, Mahalunge Rd, Lalit Estate, Baner, Pune, MH. – 411045",
    },
    {
      id: "2",
      country: "/images/us.svg",
      title: "USA",
      address: "1302 Lords Ct Wilmington, MA 01887, USA",
    },
    {
      id: "3",
      country: "/images/uk.svg",
      title: "UK",
      address: "Suite 415c, MARGARET POWELL HOUSE, Midsummer Blvd"
    },
    {
      id: "4",
      country: "/images/sa.png",
      title: "South Africa",
      address: "8 Ashwood Drive, Centurion 0185, South Africa",
    },
    {
      id: "5",
      country: "/images/sg.svg",
      title: "Singapore",
      address: " 10 High Street Centre1 North Bridge Rd, Singapore 179094",
    },
  
    {
      id: "6",
      country: "/images/Ireland-Flag.svg",
      title: "Ireland",
      address: "Cavan Enterprise Centre, Dublin Road, Cavan, Co. Cavan , Ireland, H12 EW84",
    },
  ]);

  return (
    <Grid container spacing={3}
    style={{
  
      justifyContent: 'center',
      marginTop: '17px',
      background: "#0483fb",
       justifyContent: "center", 
       marginTop: "17px"
    }}
    >
      {data.map((item) => (
        <Grid key={item.id} item xs={12} sm={4} md={3} style={{ marginLeft: "5px", marginRight: "5px", }} >
          <Card style={{ textAlign: 'center', color: "#fff", border: "1px solid #fff", background: "#0483fb", height: "250px", }}>
            <CardMedia
              component="img"
              alt={item.title}
              image={item.country}
              style={{ width: '60px', height: '60px', borderRadius: '50%', margin: "auto", marginTop: "20px" }}
            />
            <CardContent style={{ padding: "20px", textAlign: "center" }}>
              <Typography variant='h5' style={{ fontSize: "22px", fontWeight: '500', fontFamily: "poppins", marginTop: "5px" }}>{item.title}</Typography>
              <Typography variant='body1' style={{ fontSize: "16px", fontFamily: "poppins", textAlign: "center" }}>{item.address}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} style={{ textAlign: 'center', }}
      >
        <Typography variant='h5' style={{ fontSize: "16px", fontWeight: '500', fontFamily: "poppins", color: "#fff" }}>© 2024 Mobiloitte India Private Limited. All Rights Reserved</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;