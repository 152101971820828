import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,

  Grid,
  MenuItem,
  Box,
  Container,
  Menu,

} from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';


import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MenuIcon from "@material-ui/icons/Menu";

import React, { useState, useEffect, useRef, useContext } from "react";

import Logo from "./../../component/Logo";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import SettingsContext from "src/context/SettingsContext";
import Scroll from "react-scroll";
import CloseIcon from '@material-ui/icons/Close';



const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "17px",
    lineHeight: "21px",
    fontWeight: "600",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    color: "#222",
    padding: "0px 27px",
    letterSpacing: "1px",
    textDecoration: "none",
    fontFamily: "montserrat",
    fontWeight: "600",
    marginLeft: "0",
    textDecoration: "none ",
    cursor: "pointer",
    "@media (min-width: 992px) and (max-width: 1450px)": {
      padding: "0px"
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    "@media (max-width: 990px)": {
      padding: "0px",
      width: "100%",
      display: "block",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      marginBottom: "20px",
    },
    "@media (max-width: 1420px)": {
      marginLeft: "-10px"
    },
    "@media (max-width: 1340px)": {
      marginLeft: "-18px"
    },
    "@media (max-width: 1300px)": {
      marginLeft: "-36px"
    },
    "@media (max-width: 1170px)": {
      fontSize: "16px",
      marginLeft: "-21px"
    },
    "@media (min-width: 320px)": {
      fontSize: "15px",
      marginLeft: "10px",

    },
    "@media (max-width: 1100px)": {
      fontSize: "13px",

    },

    "@media (max-width: 1010px)": {
      marginLeft: "0px",
      padding: "5px"

    },
    "&.activeMenu": {
      color: "#06038D",
    },
    "&:hover": {
      color: "#046A38",
    },
  },
  menuButton1: {
    width: "100%", textAlign: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 990px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
      background: "red"

    },
  },
  maindrawer: {
    height: "100%",
    background: "red",
    width: "260px",
  },
  globalBtn: {
    fontSize: "15px",
    padding: "10px",
    width: "210px",
    borderRadius: "10px",

    "@media (min-width: 320px) and (max-width: 420px)": {
      position: "absolute",  // Corrected the spelling
      top: "390px !important",
      right: "65px",

      position: "fixed"


    },

    "@media (min-width: 420px) and (max-width: 520px)": {
      position: "absolute",  // Corrected the spelling
      top: "390px",
      right: "126px",
      position: "fixed"

    },
    "@media (min-width: 520px) and (max-width: 620px)": {
      position: "absolute",  // Corrected the spelling
      // top: "350px",
      right: "175px",
      // position: "fixed"

    },
    "@media (min-width: 620px) and (max-width: 720px)": {
      position: "absolute",  // Corrected the spelling
      // top: "350px",
      right: "250px",
      top: "360px"
      // position: "fixed"

    },
    "@media (min-width: 720px) and (max-width: 820px)": {
      position: "absolute",  // Corrected the spelling
      // top: "350px",
      right: "280px",
      top: "360px"

    },
    "@media (min-width: 820px) and (max-width: 991px)": {
      // position: "absolute",  // Corrected the spelling
      // top: "350px",
      // right: "10px",
      width: "167px",
      fontSize: "12px",


    },





    "&:hover": { // Apply styles when hovered
      backgroundColor: "blue", // Change background color to blue
    },


  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "218px",
    marginBottom: "30px",
    marginTop: "30px",
  },
  drawerContainer: {
    padding: "20px",
    height: "100%",
    background: "#000",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    marginTop: "10px",
    paddingTop: "10px"
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "red",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "red",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "19px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    marginLeft: "10px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  logoBox: {
    position: "relative",
    top: 10,
    left: 0,
    margin: "0 1.5rem",


    // [theme.breakpoints.down("sm")]: {
    //   position: "initial",
    // },
    "@media (max-width: 990px)": {
      position: "initial",
      top: 0,

    },
    "& img": {
      maxWidth: 210,
      marginLeft: -130,
      marginTop: -20,

      "@media (max-width: 995px)": {
        width: "auto",
        // height:"24px",
        maxWidth: "fit-content",
        marginLeft: 5,
        marginTop: 12
      },

      "@media (max-width: 1510px)": {
        maxWidth: 164,
        marginLeft: -110,
      },
      "@media (max-width: 1450px)": {
        maxWidth: 164,
        marginLeft: -90,
      },
      "@media (max-width: 1420px)": {
        maxWidth: 164,
        marginLeft: -60,
      },
      "@media (max-width: 1350px)": {
        maxWidth: 144,
        marginLeft: -47,
      },
      "@media (max-width: 1340px)": {
        maxWidth: 144,

        marginLeft: -7,
      },

      "@media (max-width: 1024px)": {

        marginLeft: 8,
        marginTop: -10
      },
      "@media (max-width: 320px)": {

        marginLeft: 5,
        marginTop: 15
      },
      "@media (max-width: 992px)": {

        marginLeft: 2,
        marginTop: 9
      },




    },
  },
  mintBtn: {
    height: "100%",
    backgroundColor: "transparent",
    border: "2px solid #06038D",
    color: "#000",
    fontSize: "20px !important",
    fontWeight: "600",
    textTransform: "uppercase",
    boxShadow: "none",
    borderRadius: 0,
    right: "48px",
    width: "190px",
    left: "-39px",

    "@media (max-width: 990px)": {
      borderRadius: " 40px",
      //  height: "46px",
      marginTop: "20px",
      width: "calc(100% - 60px)",
      fontSize: "16px !important",
      maxWidth: "300px",
    },
    "@media (min-width: 990px)": {
      fontSize: "16px !important"
    },
    "@media (max-width: 1610px)": {
      position: "relative",
      right: "60px",

    },
    "@media (max-width: 1450px)": {
      position: "relative",
      right: "90px",
      width: "180px",

    },
    "@media (max-width: 1450px)": {
      position: "relative",
      right: "55px",
      width: "180px",

    },
    "@media (max-width: 1035px)": {
      position: "relative",
      right: "65px",
      width: "180px",

    },

    "&:hover": {
      backgroundColor: "#046A38",
      border: "2px solid #046A38",
      color: "#fff",
    },
  },
  socailIcons: {
    paddingRight: "45px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingRight: "10px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      height: "25px",
      width: "1px",
      backgroundColor: "#ccc",
      right: "20px",
      top: "50%",
      transform: "translateY(-50%)",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  accountBox: {
    "& button": {
      color: "#6442f4",
      width: "165px !important",
      borderRadius: "10px",
      height: "46px",
      [theme.breakpoints.down("md")]: {
        width: "137px !important",
        marginLeft: "0px",
        fontSize: "12px !important",

      },

    },
  },
  headerbar: {
    border: "none",
    borderRadius: "0px !important",
    boxShadow: "0px 2px 15px rgb(209 209 209)",
    backgroundColor: "#fff",
    opacity: "1",
    position: "fixed",
    top: "0px",
    "@media (max-width: 990px)": {
      backgroundColor: "#000",
      boxShadow: "none",
    },


  },

  closeButton: {
    position: "absolute",
    width: "15px",
    color: "#6adeea",
    right: "20px",
    top: "10px",
  },

}));

export default function Header() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const reload = () => {
    window.location.reload();
  };



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    menuButton,
    toolbar,
    logoBox,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    submenu, closeButton,
    mintBtn,
    socailIcons,
    accountBox,
    wallet,
    headerbar,
    globalBtn,
  } = useStyles();
  const history = useHistory();
  console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });


  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 991
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const themeSeeting = useContext(SettingsContext);
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const [open, setOpen] = React.useState(false);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const handleImageClick = () => {
    // Reload the page when the image is clicked
    window.location.reload();
  };

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <Grid
          container
          style={{
            paddingLeft: "0px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        // style={{ alignItems: "center" }}
        >
          {/* <Grid item xs={1}></Grid> */}




          <Grid item xs={12} align="right">


            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {menuLinks}

              <Box className="flexBetween">
                <Box pr={4} mr={3}>{mintButton}</Box>
                {Profile}</Box>
            </Box>
          </Grid>


        </Grid>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const displayMobile = () => {
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Toolbar className={mainHeader}>
        <IconButton
          className={drawericon}
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleClickOpen,
          }}
        >
          <MenuIcon
            width="60px"
            height="60px"
            style={{ color: "#6ADEEA", fontSize: "30px", marginTop: "12px", marginRight: "10px" }}
          />
        </IconButton>

        <Dialog fullScreen open={open} onClose={handleClose} >
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" className={closeButton}>
            <CloseIcon />
          </IconButton>

          <div className={drawerContainer}>
            <img className={logoDrawer} src="/images/2.png" alt="" />
            {mintButton}
            {menuLinks}
            {MobileMenu}
          </div>
        </Dialog>

        <div>{mobileLogo}</div>
      </Toolbar>
    );
  };


  const femmecubatorLogo = (
    <Box className={logoBox}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const mobileLogo = (

    <Box className={logoBox}>
      <img
        src="/images/1.png"
        alt="logo"
        onClick={handleImageClick}
        style={{ cursor: "pointer" }}

      />
    </Box>


  );
  const mintButton = (
    <ScrollLink >
      <Button
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        style={{ fontSize: "22px !important", background: "rgb(0, 150, 136)", color: "#fff" }}
        className={globalBtn}
      >
        Global Presence<ExpandMoreIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <img src="/images/ind.svg" style={{ borderRadius: "50%", padding: "7px", width: "30px" }} />
          </ListItemIcon>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
              onClick={() => window.open('https://www.mobiloitte.com/', '_blank')}
            >
              Global
            </span>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <img src="/images/sg.svg" style={{ borderRadius: "50%", padding: "7px", width: "30px" }} />
          </ListItemIcon>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
              onClick={() => window.open('https://mobiloitte.com.sg/', '_blank')}
            >
              Singapore
            </span>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <img src="/images/us.svg" style={{ borderRadius: "50%", padding: "7px", width: "30px" }} />
          </ListItemIcon>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'white', justifyContent: "space-between" }}
              onClick={() => window.open('https://www.mobiloitte.us/', '_blank')}
            >
              United States
            </span>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <img src="/images/uk.svg" style={{ borderRadius: "50%", padding: "7px", width: "30px" }} />
          </ListItemIcon>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
              onClick={() => window.open('https://www.mobiloitte.co.uk/', '_blank')}
            >
              United Kingdom
            </span>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <img src="/images/ind.svg" style={{ borderRadius: "50%", padding: "7px", width: "30px" }} />
          </ListItemIcon>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
              onClick={() => window.open('https://www.mobiloitte.eu/', '_blank')}
            >
              Ireland
            </span>
          </div>
        </StyledMenuItem>
      </StyledMenu>
    </ScrollLink>
  );




  const menuLinks = (

    <Box align="left" className="pl24">
      {history.location.pathname !== "/" ? <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="industries"
        onClick={() => history.push("/")}
      >
        Industries
      </ScrollLink> : <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="industries"
        onClick={() => history.push("/")}
      >
        Industries
      </ScrollLink>}

      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="services"
        onClick={() => history.push("/")}
      >
        Services
      </ScrollLink>

      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="testimonials"
        onClick={() => history.push("/")}
      >
        Testimonial
      </ScrollLink>
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="portfolio"
        onClick={() => history.push("/")}
      >
        Portfolio
      </ScrollLink>
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="whitepaper"
        onClick={() => history.push("/career")}
      >
        Career
      </ScrollLink>


    </Box>
  );
  const Profile = (
    <Box className={accountBox}>
      <ScrollLink smooth={true} duration={500} className={menuButton} to="contact" onClick={() => history.push("/?id=portfolio")}>
        <Button variant="contained" color="secondary" fullWidth className={mintBtn}>
          Contact Us
        </Button>
      </ScrollLink>
    </Box>
  );
  const MobileMenu = (

    <Box>





    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={headerbar}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>


    </>
  );
}
