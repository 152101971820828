import React from 'react'
import LoginForm from './Jobapply'
import ApplyBanner from './ApplyBanner'
import Basic from "src/Basic/Basic"
const JobApplication = () => {
  return (
    <Basic style={{ marginTop: "120px" }}>
    <>
      <ApplyBanner />
      <LoginForm />
    </>
  </Basic>
  )
}

export default JobApplication