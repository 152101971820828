import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Hidden,
} from "@material-ui/core";
import React from "react";
import Mobiloitte from "src/component/newmobo.gif";
import FavoriteIcon from '@material-ui/icons/Favorite';
import PeopleIcon from '@material-ui/icons/People';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import GavelIcon from '@material-ui/icons/Gavel';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const useStyles = makeStyles((theme) => ({
  contentSection5:{
  marginTop: "0px !important",
  [theme.breakpoints.down("sm")]: {
 display: "none",
  },
  },
  contentSection1: {
    borderRadius: "15px",
    padding: "23px 26px",
    position: "relative",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: "23px 16px",
      marginLeft: "0px",
      marginTop: "60px",
    },   [theme.breakpoints.down("md")]: {
      padding: "23px 16px",
      marginLeft: "0px",
      // marginTop: "-60px",
    },
    "& h2": {
      color: "#000",
      fontWeight: "500",
      textAlign: "left",
      fontSize: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
      },
    },
    "& p": {
      marginTop: "1.5rem",
      color: "#000",
      fontWeight: "400",
      lineHeight: "1.5",
      [theme.breakpoints.down("sm")]: {
        marginTop: "1rem",
      },
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
}));

export default function Guiding() {
  const classes = useStyles();

  return (
    <Box>
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="center">
          <Grid item lg={6} xs={12}>
            <Box className={`${classes.contentSection1} line martop35`}>
              <Typography variant="h2">
                Our Guiding Principles
              </Typography>
              <Typography variant="body2" style={{ fontSize: "21px", marginLeft: "0px" }}>
                We are resolute in our dedication to these foundational principles, embedding them into the very fabric of our organization. As we shape our platform and offerings, we do so with unwavering belief, understanding that by empowering individuals to streamline their responsibilities and wholeheartedly immerse themselves in their work, no matter where they are, we bring substantial, tangible benefits to their lives.
              </Typography>

              <Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
    <Box display="flex" alignItems="center" marginTop="0px">
      <FavoriteIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Dedication</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Box display="flex" alignItems="center" className={classes.contentSection5}>
      <GavelIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Empowerment</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={6} order={{ xs: 3, sm: 2 }}>
    <Box display="flex" alignItems="center">
      <PeopleIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Integration</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={6} order={{ xs: 4, sm: 3 }}>
    <Box display="flex" alignItems="center">
      <InsertEmoticonIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Streamlining</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={6} order={{ xs: 5, sm: 4 }}>
    <Box display="flex" alignItems="center">
      <FilterVintageIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Conviction</Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={6} order={{ xs: 6, sm: 5 }}>
    <Box display="flex" alignItems="center">
      <ThumbUpIcon style={{ marginTop: "20px" }} />
      <Typography variant="body2">Meaningful</Typography>
    </Box>
  </Grid>
</Grid>



            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box className="videoBox">
              <img src={Mobiloitte} alt="logo" style={{ width: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
