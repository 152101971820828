import React from 'react'
import TopBar from '../layouts/HomeLayout/TopBar'
import Footer from '../views/pages/Home/Footer'
import Contact from '../views/pages/Home/Contact'

const Basic = ({ children }) => {
    return (
        <>
            <TopBar />
            {children}
            <>
                <Contact />
                <Footer />
            </>

        </>
    )
}

export default Basic