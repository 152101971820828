import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Container,
  Snackbar,
  makeStyles
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import ResponsiveAccordion from "../Home/Accordian";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { result, values } from "lodash";



const useStyles = makeStyles((theme) => ({
  // Your existing styles...
  // Add more styles as needed
  mainbox: {
    marginTop: "130px",
  },
  logoo: {
    marginTop: "100px",
    width: "30%",
    marginLeft: "-10px",
  },
  heading: {
    color: "#fff",
  },
  btnBox: {
    marginTop: "20px",
  },
  follow: {
    fontSize: "25px !important",
    marginInlineStart: "-24px",
    marginTop: "80px",
    fontFamily: 'montserrat',
    fontWeight: "700",
    color: "#fff !important",
  },
  socialContainer: {
    marginLeft: "-60px",
  },
  '@media (min-width: 320px) and (max-width: 599px)': {
    logo: {
      width: "50% !important",
    },
    mainbox: {
      marginTop: "0px",
    },
    follow: {
      marginInlineStart: "-13px",
    },
    socialContainer: {
      marginLeft: "-40px",
    },
  },

  '@media (min-width: 432px) and (max-width: 580px)': {
    heading: {
      paddingLeft: "0px",
    },
  },
  '@media (min-width: 320px) and (max-width: 570px)': {
    logoo: {
      marginTop: '0px',
      width: '70%',
    },
  },
  '@media (min-width: 600px) and (max-width: 1024px)': {
    logoo: {
      width: "60%",
      height: "auto",
    }
  },

  [theme.breakpoints.down("md")]: {
    order0: {
      marginTop: "450px",
      marginLeft: "0px !important",
    },
    order0: {
      order: 2,
    },
    order1: {
      order: 1,
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedIcon, setSelectedIcon] = React.useState('');
  const [listValue, setListvalue] = useState("");

  console.log("api", selectedIcon);

  const submitData = async (values) => {
    try {
      setLoader(true);
      const response = await axios.post("url", {
        name: values.name,
        email: values.email,
        phoneNo: values.phoneNo,
        subject: values.subject,
        message: values.msg,
      });
      console.log("Contact Us Response:", response);
      if (response.data.responseCode === 200) {
        toast.success('Successfully Submitted');
        setIsSubmitted(true);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error Contact Us:", error);
      setLoader(false);
    }
  };
  return (
    <Box className={classes.mainbox}>
      <Container maxWidth="lg" style={{ background: "#0483fb", maxWidth: "100%", marginTop: "20px" }}>
        <Grid container style={{ padding: { md: "0rem 10rem", sm: "0rem 5rem" } }} spacing={6}>
          <Grid item xs={12} sm={6} md={6} style={{ margin: { md: "0rem 10rem", sm: "0rem 5rem", lg: "0rem 50rem" } }} className="order1" >
            <Box style={{ marginTop: "70px" }} className={classes.logoo}>
              <img src="/images/2.png" alt="logo" />
            </Box>
            <Box>
              <Typography variant="body2" style={{ fontSize: "20px", fontFamily: 'montserrat', fontWeight: "400", color: "#fff" }}>
                Phone: +91 - 9999525801
              </Typography><br></br>
              <Typography variant="body2" style={{ fontSize: "20px", fontFamily: 'montserrat', fontWeight: "400", color: "#fff" }}>
                Email: connect@mobiloitte.com
              </Typography><br></br>
              <Typography variant="body2" style={{ fontSize: "20px", fontFamily: 'montserrat', fontWeight: "400", color: "#fff" }}>
                Address: D-115, Okhla Phase -I, New Delhi – 110020
              </Typography>
            </Box>
            <Box>
            </Box>
            <Container>
              {/* ... (your existing code) */}
              <Box >
                <Typography variant="h3" className={classes.follow}>
                  Follow Us
                </Typography>
              </Box>
              <Container className={classes.socialContainer} >
                <IconButton component="a" href="https://www.facebook.com/mobiloitte/" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon style={{ fontSize: "2.5rem", }} />
                </IconButton>
                <IconButton component="a" href="https://twitter.com/Mobiloitte" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon style={{ fontSize: "2.5rem" }} />
                </IconButton>
                <IconButton component="a" href="https://www.linkedin.com/company/mobiloitte" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon style={{ fontSize: "2.5rem" }} />
                </IconButton>
                <IconButton component="a" href="https://www.instagram.com/mobiloitte/" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon style={{ fontSize: "2.5rem", }} />
                </IconButton>
              </Container>
              {/* ... (your existing code) */}
            </Container>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className="order0">
            <Box mb={5} style={{ marginTop: '70px' }}>
              <Typography variant="h6" style={{ textAlign: 'left', fontFamily: 'montserrat', fontWeight: "500", color: "#fff" }}>
                Lets Talk us
              </Typography>
              <Typography variant="h1" style={{ textAlign: 'start', fontWeight: '700', fontFamily: 'montserrat', fontSize: "45px", }} className={classes.heading}>
                Get In Touch
              </Typography>
            </Box>

            <Formik
              initialValues={{
                email: '',
                name: '',
                phoneNo: '', // <-- Ensure this matches the name in validationSchema
                subject: 'Other',
                msg: '',
                file: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required('Please enter email'),
                name: Yup.string().required('Please Enter Name'),
                phoneNo: Yup.string()
                  .matches(/^\d+$/, 'Invalid phone number') // Only digits allowed
                  .required('Required'),
                file: Yup.mixed().required('A file is required'),
                subject: Yup.string().required('Please enter Subject'),
                msg: Yup.string().required('Please enter Message field'),
              })}
              onSubmit={(values, { resetForm }) => {
                // submitData(values);
                console.log("Form values:", values);
                resetForm();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className={classes.maintext}>
                    <Box mt={2} style={{ position: 'relative', marginBottom: '15px' }}>
                      <label style={{ fontWeight: '500', fontFamily: 'montserrat', fontSize: "18px", color: "#fff" }}>Name :</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                            borderBottom: `0px solid ${touched.name && values.name ? 'transparent' : '#fff'}`,
                          },
                        }}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name && <span className={classes.validationError}>{errors.name}</span>}
                        name="name"
                        onBlur={handleBlur}
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Box>

                    {/* Email Field */}
                    <Box style={{ position: 'relative', marginBottom: '15px' }}>
                      <label style={{ fontWeight: '500', fontFamily: 'montserrat', fontSize: "18px", color: "#fff" }}>Email :</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                            borderBottom: `0px solid ${touched.email && values.email ? 'transparent' : '#fff'}`,
                          },
                        }}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email && <span className={classes.validationError}>{errors.email}</span>}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        type="email"
                      />
                    </Box>

                    {/* Phone Number Field */}
                    <Box style={{ position: 'relative', marginBottom: '15px' }}>
                      <label style={{ fontWeight: '500', fontFamily: 'montserrat', fontSize: "18px", color: "#fff" }}>Phone Number :</label>
                      <TextField
                        id="outlined-basic"
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                            borderBottom: `0px solid ${touched.phoneNo && values.phoneNo ? 'transparent' : '#fff'}`,
                          },
                        }}
                        error={Boolean(touched.phoneNo && errors.phoneNo)}
                        fullWidth
                        helperText={touched.phoneNo && errors.phoneNo && <span className={classes.validationError}>{errors.phoneNo}</span>}
                        name="phoneNo"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="tel"
                        value={values.phoneNo}
                      />
                    </Box>


                    <ResponsiveAccordion value={values.subject} />

                    <Box style={{ position: 'relative', marginBottom: '15px', marginTop: '15px' }}>
                      <label style={{ fontWeight: '500', fontFamily: 'montserrat', fontSize: "20px", color: "#fff" }}>Message :</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        multiline
                        rows={8}  // Adjust the number of rows to increase the height
                        maxRows={16}  // Adjust the maxRows accordingly
                        fullWidth
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                            borderBottom: `1px solid ${touched.msg && values.msg ? 'transparent' : 'black'}`,
                            borderRadius: '8px',
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontWeight: '500',
                            fontFamily: 'montserrat',
                            fontSize: '18px',
                            color: "#fff",
                            position: 'absolute',
                            top: 0,
                            left: '10px',
                            transform: 'translateY(10px)',
                            pointerEvents: 'none',
                            transition: '0.2s',
                            backgroundColor: '#fff',
                            padding: '0 5px',
                          },
                        }}
                        error={Boolean(touched.msg && errors.msg)}
                        helperText={touched.msg && errors.msg}
                        name="msg"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.msg}
                      />
                    </Box>


                    <Box mt={4}>
                      <Button
                        color="primary"
                        // disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                        className="btnBox"

                        style={{ width: "210px", fontSize: "16px", borderRadius: "10px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>

      {/* Success Snackbar */}
      <Snackbar
        open={isSubmitted}
        autoHideDuration={5000}
        onClose={() => setIsSubmitted(false)}
        message="Form submitted successfully!"
      />
    </Box>
  );
};

export default Contact;
