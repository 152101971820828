import { Box, Container, Typography, } from "@material-ui/core";
import {React,useEffect} from "react";
import Scroll from "react-scroll";
import { Button,makeStyles } from "@material-ui/core"
import { Link } from 'react-router-dom';
import Basic from "src/Basic/Basic";
const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: '40px',
      fontFamily: 'poppins',
      fontWeight: '400',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px', 

        marginTop: "65px",// Adjust font size for smaller screens
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        marginTop: '32px',
        marginLeft: '-2px'
        // Adjust font size for extra small screens
      },
    },
  }));
function Softwarejobdetail() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
    const ScrollLink = Scroll.Link;
    const classes = useStyles();
    const styles = {
        header: {
            backgroundColor: "#00247E",
            color: "white",
            marginTop: "5%"
        },
        
        button: {
            color: "#fff",
            backgroundColor: "rgb(0, 150, 136)",
            border: "none",
            textTransform: "none",
            height: "3rem",
            width: "8rem",
            fontSize: "18px",
            marginTop: "40px",
            '&:hover': {
                backgroundColor: "#06038D", // Change the background color on hover
            },
        },
        paragraph: {
            display: "flex",
            flexDirection: "row",
            
        },
   
        
    };

    return (
        <Basic>

       
        <Box>
            <Box sx={styles.header}>
                <Box sx={{ marginLeft: "4%", paddingTop: "9%", paddingBottom: "8%" }}>
                    <Container>
                        <Typography variant="h3" style={{ fontSize: "40px", fontFamily: "poppins", fontWeight: "400" }} className={classes.header}>
                        Software Engineer
                        </Typography>
                    </Container>

                    <Container>
                        <Typography variant="h3" style={{ fontSize: "30px", marginTop: "20px", fontFamily: "poppins", fontWeight: "400" }}>India</Typography>
                    </Container>

                    <Container>
                        <ScrollLink smooth={true} duration={500} to="pathway">
                            <Link to="JobApplication">
                            <Button className={classes.contactus} style={{ marginTop: "20px", ...styles.button }} >
                                Apply Now
                            </Button>
                            </Link>
                         
                        </ScrollLink>
                    </Container>
                </Box>
            </Box>

            {/* --------------------------------------------------- */}

            <Box sx={{ marginLeft: "4%", paddingTop: "3%", paddingBottom: "3%" }}>
                <Container>
                    <Typography variant="h5" style={{ fontWeight: "550", fontSize: "30px" }}>
                        <span>Duties and Responsibilities</span>
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>
                
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        
                    <span className="dot-before">&#8226;</span> Design, Develop, Test, and Maintain Software Solutions Tailored to the India Market.
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Collaborate with Product Managers, Designers, and Cross-Functional Teams to Define Project Requirements and Objectives in the India .
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Write Clean, Efficient, and Maintainable Code in Compliance with Industry Best Practices and Standards for the India  market.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Troubleshoot and Debug Software Issues, Providing Timely Resolutions and Improvements in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Stay Informed About Emerging Technologies and Trends in Software Development, Especially Those Relevant to the India .
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Participate in Code Reviews and Mentor Junior Developers to Enhance the Team's Skills in the India .
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                      <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Ensure Software Security, Scalability, and Performance, Considering India  Data Protection Regulations.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span>  Collaborate with Local Clients and Stakeholders to Gather and Incorporate Feedback into Software Development Processes in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Maintain Up-to-Date Documentation, Including User Manuals and Technical Guides for the India .
                    </Typography>
                </Container>
                <br>
                </br>
                <Container style={styles.paragraph}>
                <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                    <span className="dot-before">&#8226;</span> Adhere to Project Timelines and Deliver Software Solutions that Meet or Exceed Client Expectations in the India .
                    </Typography>
                </Container>
                {/* Remaining responsibilities */}
            </Box>
            <Container >
                    <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Salary :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    According to the Market
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Job Type :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    Office/Hybrid
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Location :
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    India
                    </Typography>
                </Container>
            {/* Remaining content */}
        </Box>
        </Basic>
    );
}

export default Softwarejobdetail;
