import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Jobapply.css'; // You can define your CSS for styling
import axios from 'axios';

const LoginForm = () => {


const handleSubmit = async(values, { setSubmitting }) =>{

    try {
       
        const response = await axios.post('http://172.16.1.131:2024/admin/contactUs', values);

        // Handle the response as needed (e.g., show a success message)
        console.log('API Response:', response.data);

        // Reset the form and any loading state
        setSubmitting(false);
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error('API Error:', error);

        // Reset the form and any loading state
        setSubmitting(false);
    }
}




    return (
        <div className="login-form-container">
            
            <h2>Application Form</h2>
            <Formik
                initialValues={{ fullName: '', email: '', phoneNumber: '', file: null, message: '' }}
                validationSchema={Yup.object({
                    fullName: Yup.string()
                        .required('Required'),
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Required'),
                    phoneNumber: Yup.string()
                        .matches(/^\d+$/, 'Invalid phone number') // Only digits allowed
                        .required('Required'),
                    file: Yup.mixed().required('A file is required'),
                    message: Yup.string()
                        .required('Required'),
                })}
                // onSubmit={(values, { setSubmitting }) => {
                //     setTimeout(() => {
                //         alert(JSON.stringify(values, null, 2));
                //         setSubmitting(false);
                //     }, 400);
                // }}
                onSubmit={handleSubmit}
            >
                <Form className="login-form">
                    <div className="form-control">
                        <label htmlFor="fullName">Full Name</label>
                        <Field type="text" id="fullName" name="fullName"  style={{padding: "10px", border: "1px solid gray"}}/>
                        <ErrorMessage name="fullName" component="div" className="error-message" />
                    </div>
                    <div className="form-control">
                        <label htmlFor="email">Email</label>
                        <Field type="email" id="email" name="email" style={{padding: "10px", border: "1px solid gray"}}/>
                        <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                    <div className="form-control">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <Field type="text" id="phoneNumber" name="phoneNumber" style={{padding: "10px", border: "1px solid gray"}}/>
                        <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                    </div>
                    <div className="form-control">
                        <label htmlFor="file">Upload File</label>
                        <Field type="file" id="file" name="file"style={{padding: "10px", border: "1px solid gray"}} />
                        <ErrorMessage name="file" component="div" className="error-message" />
                    </div>
                    <div className="form-control">
                        <label htmlFor="message">Message</label>
                        <Field as="textarea" id="message" name="message"style={{padding: "50px 0px", border: "1px solid gray", colour: "red"}} />
                        <ErrorMessage name="message" component="div" className="error-message" />
                    </div>
                    <button type="submit">Submit</button>
                </Form>
            </Formik>
        </div>
    );
}

export default LoginForm;
