import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import Career from "./views/pages/Career/Career";
import BusinessDevelopmentExecutive from "./views/pages/Career/BusinessDevelopmentExecutive";
import JobApplication from './views/pages/Job-apply/JobApplication';
import Softwarejobdetail from "src/views/pages/Career/Softwarejobdetail";
import SeniorConsultan from "./views/pages/Career/SeniorConsultan";


export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },





  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/Career",
    layout: Career,
    component: lazy(() => import("src/views/pages/Career/Career")),
  },


  {
    exact: true,
    path: "/JobApplication",
    layout:JobApplication,
    component: lazy(() => import("src/views/pages/Job-apply/JobApplication")),
  },
  
  {
    exact: true,
    path: "/softwarejobdetail",
    layout:Softwarejobdetail,
    component: lazy(() => import("src/views/pages/Career/Softwarejobdetail")),
  },
  {
    exact: true,
    path: "/SeniorConsultan",
    layout:SeniorConsultan,
    component: lazy(() => import("src/views/pages/Career/SeniorConsultan")),
  },
  {
    exact: true,
    path: "/business",
    layout:BusinessDevelopmentExecutive,
    component: lazy(() => import("src/views/pages/Career/BusinessDevelopmentExecutive")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
