import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React from "react";
import Mobiloitte from "src/component/working.svg";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles((theme) => ({
    contentSection1: {
        borderRadius: "15px",
        padding: "23px 26px",
        fontSize: "50px",
        position: "relative",
        fontSize: "850px",
        "& h2": {
            color: "#000",
            fontWeight: "500",
            textAlign: "left",
            fontSize: "40px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "28px",
            },
          },
  
        backgroundColor: "#fff",
        [theme.breakpoints.down("sm")]: {
            padding: "23px 16px",
            fontSize: "20px !important"
        },
    },
}));

export default function Welness() {
    const classes = useStyles();

    return (
        <Box style={{marginTop: "120px"}}>
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} xs={12} sm={6}>
                        <img src={Mobiloitte} alt="logo" style={{ maxWidth: "100%" }} />
                    </Grid>
                    <Grid item lg={7} xs={12} sm={6}>
                        <Box className={classes.contentSection1}>
                            <Typography variant="h2">Fostering Achievement and Wellness</Typography>
                            <Typography variant="body1" style={{fontSize: "16px",marginTop: "20px"}}>
                                As a part of our dedication to Fostering Achievement and Wellness, we are proud to provide a comprehensive suite of benefits designed to enhance your well-being and performance. Gain access to a variety of resources and professional guidance to make your physical and mental health a top priority, ensuring your journey towards a thriving, healthy body and mind.
                            </Typography>

                            <Grid container spacing={1} style={{marginTop: "30px"}}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleRoundedIcon />
                                        <Typography variant="body2" style={{marginTop: "-1px"}}>Embrace Relaxation: Recharge, Unwind, and Create an Impact with Volunteering</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleRoundedIcon />
                                        <Typography variant="body2">Complete Health Coverage for Your Wellness</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleRoundedIcon />
                                        <Typography variant="body2">Comprehensive Well-being Initiatives and More: Cultivating Your Health and Happiness</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

