import { Box, Container, Typography, } from "@material-ui/core";
import { React, useEffect } from "react";
import Scroll from "react-scroll";
import { Button, makeStyles } from "@material-ui/core"
import Basic from "../../../Basic/Basic";
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '40px',
        fontFamily: 'poppins',
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',

            marginTop: "5px !important",// Adjust font size for smaller screens
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
            marginTop: '32px',
            marginLeft: '-2px'
            // Adjust font size for extra small screens
        },
    },
}));

function BusinessDevelopmentExecutive() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const ScrollLink = Scroll.Link;
    const classes = useStyles();
    const styles = {
        header: {
            backgroundColor: "#00247E",
            color: "white",
            marginTop: "5%",



        },
        header2: {
            width: "100%",
            maxWidth: "100%",
            marginLeft: "4%", paddingTop: "9%", paddingBottom: "8%",
            '@media (min-width: 320px) and (max-width: 768px)': {
                
                paddingTop: "25%"
            },
        },


        button: {
            color: "#fff",
            backgroundColor: "rgb(0, 150, 136)",
            border: "none",
            textTransform: "none",
            height: "3rem",
            width: "8rem",
            fontSize: "18px",
            marginTop: "40px",
            '&:hover': {
                backgroundColor: "#06038D !important", // Change the background color on hover
            },
        },
        paragraph: {
            display: 'flex',
            flexDirection: 'column',
        },


        '@media (min-width: 320px) and (max-width: 599px)': {
            india: {
                color: "red !important"
            }
        },






    };
    if (window.matchMedia("(min-width: 768px)").matches) {
        styles.header.marginTop = "60%";

    }

    if (window.matchMedia("(min-width: 1024px)").matches) {
        styles.header.marginTop = "3%";

    }
    if (window.matchMedia("(max-width: 320px)").matches) {
        styles.header.fontSize = "16px";
        styles.header.marginTop = "46px";

    }



    return (
        <Basic>

            <Box sx={styles.header}>
                <Box sx={styles.header2}>
                    <Container>
                        <Typography variant="h3" style={{ fontSize: "40px", fontFamily: "poppins", fontWeight: "400" }} className={classes.header}>
                            Business Development Executive
                        </Typography>
                    </Container>

                    <Container>
                        <Typography variant="h3" style={{ fontSize: "30px", marginTop: "20px", fontFamily: "poppins", fontWeight: "400" }}>India</Typography>
                    </Container>

                    <Container>
                        <ScrollLink smooth={true} duration={500} to="bussiness">
                            <Link to="/JobApplication">
                                <Button className={classes.contactus} style={{ marginTop: "20px", ...styles.button }} >
                                    Apply Now
                                </Button>
                            </Link>
                        </ScrollLink>
                    </Container>
                </Box>
            </Box>

            {/* --------------------------------------------------- */}

            <Box sx={{ marginLeft: "4%", paddingTop: "3%", paddingBottom: "3%" }}>
                <Container>
                    <Typography variant="h5" style={{ fontWeight: "550", fontSize: "30px" }}>
                        <span>Duties and Responsibilities</span>
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>

                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>

                        <span className="dot-before">&#8226;</span> Identify and Target Potential Clients and Markets within the India
                    </Typography>
                </Container>
                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Develop and Execute a Strategic Sales Plan to Achieve Revenue
                        Targets in the India.
                    </Typography>
                </Container>

                <br />
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Build and Maintain Strong Relationships with Key Stakeholders, including Local Businesses, Government Agencies, and Industry Associations in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Conduct Market Research to Identify Emerging Trends and Opportunities in the India Market.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span>Prepare and Deliver Persuasive Sales Presentations to Prospective India Clients.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Negotiate Contracts and Pricing Agreements with India Clients, Ensuring Mutually Beneficial Terms.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Collaborate with Cross-Functional Teams to Develop Customized Solutions Meeting Client Needs in the India
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span>  Stay Updated on Industry Regulations and Compliance Requirements in the India.
                    </Typography>
                </Container>
                <br>
                </br>
                <Container sx={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Attend Industry Events and Networking Opportunities to Expand the Company's Presence in the India  Market..
                    </Typography>
                </Container>
                <br>
                </br>
                <Container style={styles.paragraph}>
                    <Typography style={{ marginLeft: "-0.2%", fontSize: "18px" }}>
                        <span className="dot-before">&#8226;</span> Provide Regular Reports and Updates on Sales Performance and Market Insights to the Management Team, specifically for the India region.
                    </Typography>
                </Container>
                {/* Remaining responsibilities */}
            </Box>
            <Container >
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Salary :
                </Typography>
            </Container>
            <br />
            <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    According to the Market
                </Typography>
            </Container>
            <br />
            <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Job Type :
                </Typography>
            </Container>
            <br />
            <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "18px" }}>
                    Office/Hybrid
                </Typography>
            </Container>
            <br />
            <Container sx={styles.paragraph}>
                <Typography style={{ marginLeft: "2.5%", fontSize: "38px" }}>
                    Location :
                </Typography>
            </Container>
            <br />
            <Container sx={styles.paragraph} className="india">
                <Typography style={{ marginLeft: "2.5%", fontSize: "18px", }}>
                    India
                </Typography>
            </Container>
            {/* Remaining content */}

        </Basic>
    );
}

export default BusinessDevelopmentExecutive;
