


import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  label: {
    fontWeight: '400',
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#fff',
    marginBottom: '8px',
  },
}));

const ResponsiveAccordion = ({ setListvalue }) => {
  const classes = useStyles();
  const [selectedIcon, setSelectedIcon] = React.useState('');

  const handleChange = (event) => {
    setSelectedIcon(event.target.value);
   
  };
  const results = selectedIcon;
  // console.log(selectedIcon);
  return (
    <div>
      <InputLabel className={classes.label} id="icon-dropdown-label">
        I am Looking for
      </InputLabel>
      <FormControl className={classes.formControl} >
        <Select
          labelId="icon-dropdown-label"
          id="icon-dropdown"
          value={selectedIcon}
          onChange={handleChange}

        >

          <MenuItem value="consulting">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Consulting & Advisory
            </span>
          </MenuItem>
          <MenuItem value="product-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Full Product Development
            </span>
          </MenuItem>
          <MenuItem value="blockchain-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Blockchain Development
            </span>
          </MenuItem>
          <MenuItem value="metaverse-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Metaverse Development
            </span>
          </MenuItem>
          <MenuItem value="cloud-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Cloud Services
            </span>
          </MenuItem>
          <MenuItem value="mobile-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Mobile Application Development
            </span>
          </MenuItem>
          <MenuItem value="web-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Web Development
            </span>
          </MenuItem>
          <MenuItem value="ai-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              AI & ML Development
            </span>
          </MenuItem>
          <MenuItem value="other-development">
            <span style={{ marginLeft: '8px', color: 'black' }}>
              Other
            </span>
          </MenuItem>
          {/* Add other MenuItems with unique values */}
        </Select>
      </FormControl>
    </div>
  );
};

export default ResponsiveAccordion;

